import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import {FaArrowDown, FaFacebook, FaInstagram} from "react-icons/fa";
import {HoverTap} from "./HoverTapButton";
import { Link } from "gatsby";
import {makeAnimated} from "./MakeAnimated";

export default function FullWidthImage(props) {
  const {
    height = 900,
    img,
    titleImage,
    title,
    subheading,
    imgPosition = "top left",
  } = props;

  return (
    <React.Fragment>
      <div
        className="margin-top-0 hero-viewport"
        style={{
          display: "grid",
          alignItems: "center",
        }}
      >
        {img?.url ? (
          <img
            src={img}
            objectFit={"cover"}
            objectPosition={imgPosition}
            style={{
              gridArea: "1/1",
              width: "100%",
            }}
            alt=""
          />
        ) : (
          <GatsbyImage
            image={img}
            objectFit={"cover"}
            objectPosition={imgPosition}
            style={{
              gridArea: "1/1",
              height: "100%",
              filter: "contrast(0.5)"
            }}
            layout="fullWidth"
            alt=""
            formats={["auto", "webp", "avif"]}
          />
        )}

        {/*<div className=>*/}
          {
            makeAnimated(
              <Link to="/booking">
                <HoverTap className="btn-stroked" stiffness={600} damping={6}>
                  Umów wizytę
                </HoverTap>
              </Link>,
              "cta-btn"
            )
          }
        {/*</div>*/}

        {(titleImage) && (
          <div className={'logo-container'}>
            {titleImage && (
              <GatsbyImage
                className="main-logo-img"
                image={titleImage}
                objectFit={"contain"}
                objectPosition={"top center"}
                layout="constrained"
                alt=""
                formats={["auto", "webp", "avif"]}
              />
            )}
          </div>
        )}
        <div className="scroll-more-indicator anim-bounce is-clickable" onClick={() => window.scrollTo({top: 0.5 * window.screen.height, behavior: "smooth"})}>
          <div className="scroll-more-indicator__text">przewiń</div>
          <FaArrowDown className="scroll-more-indicator__arrow" />
        </div>

        <div className="social-index">
          <a className="ml-1" href="https://www.instagram.com/dzika_terapia" target="_blank" rel="noopener noreferrer">
            <HoverTap>
              <FaInstagram className="icon" />
            </HoverTap>
          </a>
          <a className="ml-1" href="https://www.facebook.com/DzikaTerapia1" target="_blank" rel="noopener noreferrer">
            <HoverTap>
              <FaFacebook className="icon" />
            </HoverTap>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  titleImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
