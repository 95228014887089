import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import {HoverTap} from "./HoverTapButton";
import {Link} from "gatsby";

const showMore = (item) => {
  showMore();
}

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline is-centered m-4">
    {gridItems.map((item, idx) => {
      const headingLink = item.text?.toLowerCase()?.replaceAll(' ', '-');
      return (
        <Link key={`${idx}`} to={headingLink ? `activity#${headingLink}` : null} className="column is-one-third has-text-centered is-clickable">
          <HoverTap key={item.text}>
            <PreviewCompatibleImage className="is-one-quarter" imageInfo={item} />
            <div className="mt-3">
              <h5>{item.text}</h5>
            </div>
          </HoverTap>
        </Link>
      );
    })}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
