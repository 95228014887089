import {motion} from "framer-motion";
import React from "react";

export const makeAnimated = (children, className) => {
  const variants = {
    offscreen: {
      y: -100,
      opacity: 0
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.5,
        delay: 0.3
      }
    }
  };
  return (
    <motion.div initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                className={className}>
      <motion.div variants={variants}>
        {children}
      </motion.div>
    </motion.div>
  );
}
