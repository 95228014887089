import React, {useState} from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Layout from "../components/Layout";
import Features from "../components/Features";
import FullWidthImage from "../components/FullWidthImage";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import { motion } from "framer-motion"
import HoverTapBtn from "../components/HoverTapButton";
import {makeAnimated} from "../components/MakeAnimated";
import ContactSection from "../components/ContactSection";
import {setWasIndexLoaded} from "../util/navigation";
import CountUp from "react-countup";

const getDisplayableInstagramFeed = (instaFeed) => {
  const [dzikaTerapiaInstaFeedState] = instaFeed;
  const {isFetching, wasFetched, posts} = dzikaTerapiaInstaFeedState;
  if (!wasFetched || isFetching) {
    return <div className="insta-feed-placeholder">Pobieram posty z instagrama...</div>
  }
  if (!posts?.length) {
    return <div className="insta-feed-placeholder">Nie udało się pobrać postów z instagrama</div>
  }
  return (<Swiper spaceBetween={20}
                  centeredSlides={true}
                  autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  pagination={{
                    clickable: true
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  effect={"slide"}
                  slidesPerView={3}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 20
                    },
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30
                    }
                  }}
                  className="instagram-swiper">
    {posts.map((post, idx) => {
      return (
        <SwiperSlide key={`swiper${idx}`}>
          <div className="card is-clickable" onClick={navigateToDzikaTerapiaInstagram}>
            <div className="card-image">
              <figure className="image is-4by3">
                <img src={post.image} alt="Placeholder image" />
              </figure>
            </div>
            <div className="card-content">
              <div className="content insta-caption">
                {post.description}
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    })}
  </Swiper>);
}

const fetchInstagramFeed = async () => {
  try {
    const response = await fetch("/.netlify/functions/feed");
    return response.json();
  } catch (e) {
    console.log(e);
    return [];
  }
}

const navigateToDzikaTerapiaInstagram = () => {
  window.open('https://www.instagram.com/dzika_terapia', '_blank');
}


// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  heroTitleImage,
  mapStaticImage,
  ceoLandscapeImage,
  employeeLandscapeImage,
  title,
  subheading,
  mainpitch,
  intro,
  ceoName,
  employeeName
}) => {
  const heroImage = getImage(image) || image;
  const titleImage = getImage(heroTitleImage) || heroTitleImage;
  const mapImage = getImage(mapStaticImage) || mapStaticImage;
  const ceoImage = getImage(ceoLandscapeImage) || ceoLandscapeImage;
  const employeeImage = getImage(employeeLandscapeImage) || employeeLandscapeImage;

  const dzikaTerapiaInstaFeed = useState({isFetching: false, wasFetched: false, posts: null});
  const [dzikaTerapiaInstaFeedState, dzikaTerapiaInstaFeedSetter] = dzikaTerapiaInstaFeed;
  if (dzikaTerapiaInstaFeedState.posts == null && !dzikaTerapiaInstaFeedState.isFetching) {
    dzikaTerapiaInstaFeedSetter({isFetching: true, wasFetched: true, posts: []});
    fetchInstagramFeed().then(
      (posts) => dzikaTerapiaInstaFeedSetter({isFetching: false, wasFetched: true, posts})
    );
  }
  const countUpBaseDelay = 2;
  const dzikaTerapiaInstagramPosts = getDisplayableInstagramFeed(dzikaTerapiaInstaFeed);

  const cardVariants = {
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 0.3
      }
    }
  };

  const workers = [
    {name: ceoName, image: ceoImage, variants: {offscreen: {x: -300, opacity: 0}}},
    {name: employeeName, image: employeeImage, variants: {offscreen: {x: 300, opacity: 0}}},
  ];

  return (
    <div>
      <div>
        <FullWidthImage img={heroImage} titleImage={titleImage} title={title} subheading={subheading} />
      </div>
      <section className="section">
        <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile is-justify-content-center">
                      <h1 className="title">{mainpitch.title}</h1>
                    </div>
                    <div className="tile is-justify-content-center">
                      <hr className={'rounded'} />
                    </div>
                    <div className="tile has-text-centered-tablet">
                      <p className="subtitle">{mainpitch.description}</p>
                    </div>
                    <div className="column is-12 has-text-centered mt-6">
                      {
                        makeAnimated(
                          <Link to="/about">
                            <HoverTapBtn text="Więcej 🐗 informacji" />
                          </Link>
                        )
                      }
                    </div>
                  </div>
                  <div className="tile is-justify-content-center">
                    <hr className={'rounded'} />
                  </div>
                  <div className="columns my-6">
                    {
                      workers.map((worker, idx) => {
                        return (
                          <div className="column" key={`worker${idx}`}>
                            <motion.div
                              initial="offscreen"
                              whileInView="onscreen"
                              viewport={{ once: true, amount: 0.6 }}>
                              <motion.div
                                className="card"
                                variants={{...cardVariants, ...worker.variants}}>
                                <div className="card-image">
                                  <GatsbyImage
                                    image={worker.image}
                                    objectFit={"contain"}
                                    objectPosition={"top left"}
                                    style={{
                                      gridArea: "1/1",
                                      height: "100%",
                                    }}
                                    layout="fullWidth"
                                    alt=""
                                    formats={["auto", "webp", "avif"]}
                                  />
                                </div>
                                <div className="card-content has-text-centered">
                                  <div className={"is-size-3"}>{ worker.name }</div>
                                </div>
                              </motion.div>
                            </motion.div>
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className="column is-12 has-text-centered">
                    {
                      makeAnimated(
                        <Link to="/team">
                          <HoverTapBtn text="Więcej informacji o nas" />
                        </Link>
                      )
                    }
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <section className="section filled-section">
        <div className="container">
          <div className="">
            <div className="is-offset-1">
              <div className="content columns is-vcentered">
                <div className="column">
                  <h1 className="title">{intro.heading}</h1>
                  <Features gridItems={intro.blurbs} />
                  <div className="has-text-centered">
                    {
                      makeAnimated(
                        <Link to="/activity">
                          <HoverTapBtn text="Sprawdź jak możemy pomóc" />
                        </Link>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <Swiper spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={{
                              clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="testimonials-swiper">
                      <SwiperSlide>
                        <div className="tile has-text-centered-tablet my-3">
                          <p className="is-italic" style={{marginLeft: 64 + 'px', marginRight: 64 + 'px'}}>
                            Naprawdę świetny i profesjonalny fachowiec, który w pełni potrafi zaangażować się w problemy pacjenta. Skuteczny w swoim działaniu, z dużą wiedzą . Polecam !!!!
                          </p>
                        </div>
                        <div className="has-text-centered-tablet mt-1">
                          Bożena
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>

                        <div className="tile has-text-centered-tablet my-3">
                          <p className="is-italic" style={{marginLeft: 64 + 'px', marginRight: 64 + 'px'}}>
                            Serdecznie polecam! Łatwo można nawiązać z nim kontakt, co przekłada się na charakter oraz przebieg wizyty. Jest bardzo serdeczny, życzliwy, dzieli się wiedzą, co powoduje, ze nasz dolegliwość staje się bardziej czytelna!
                          </p>
                        </div>
                        <div className="has-text-centered-tablet mt-1">
                          Patrycja
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>

                        <div className="tile has-text-centered-tablet my-3">
                          <p className="is-italic" style={{marginLeft: 64 + 'px', marginRight: 64 + 'px'}}>
                            Świetna atmosfera, poczucie bezpieczeństwa (bardzo ważne dla osoby takiej jak ja, która lekarzy i innych terapeutów boi się jak ognia ;)), niesamowite zaangażowanie i chęć wytłumaczenia tego, co się robi i co z organizmem się dzieje. To dopiero początek przygody, ale już w tym momencie polecam z całego serca :)
                          </p>
                        </div>
                        <div className="has-text-centered-tablet mt-1">
                          Ewelina
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <div className="column is-12 my-6 has-text-centered">
                      {
                        makeAnimated(
                          <a href="https://www.znanylekarz.pl/karol-jablonski/fizjoterapeuta/tychy#tab=profile-reviews"  target="_blank" rel="noopener noreferrer">
                            <HoverTapBtn text="Więcej opinii" />
                          </a>
                        )
                      }
                    </div>
                  </div>
                  <div className="tile is-justify-content-center">
                    <hr className={'rounded'} />
                  </div>

                  {
                    // makeAnimated(
                      <div className={"level my-6 py-4 stats card"}>
                        <div className="level-item has-text-centered my-5">
                          <div>
                            <p className="heading">Wyleczonych pacjentów</p>
                            <p className="title">
                              <CountUp delay={countUpBaseDelay} enableScrollSpy={true} end={99} /> %
                            </p>
                          </div>
                        </div>
                        <div className="level-item has-text-centered my-5">
                          <div>
                            <p className="heading">Profesjonalizmu</p>
                            <p className="title">
                              <CountUp delay={countUpBaseDelay + 0.5} enableScrollSpy={true} end={100} /> %
                            </p>
                          </div>
                        </div>
                        <div className="level-item has-text-centered my-5">
                          <div>
                            <p className="heading">Zaangażowania</p>
                            <p className="title">
                              <CountUp delay={countUpBaseDelay + 1} enableScrollSpy={true} end={120} /> %
                            </p>
                          </div>
                        </div>
                        <div className="level-item has-text-centered my-5">
                          <div>
                            <p className="heading">Zadowolonych pacjentów</p>
                            <p className="title">
                              <CountUp delay={countUpBaseDelay + 1.5} enableScrollSpy={true} end={99} /> %
                            </p>
                          </div>
                        </div>
                      </div>
                    // )
                  }


                  <div className="tile is-justify-content-center">
                    <hr className={'rounded'} />
                  </div>
                  <div className="content mb-6">
                    <div className="my-6">
                      <h2 className="mt-6">Obserwuj dziką terapię na instagramie</h2>
                      {
                        dzikaTerapiaInstagramPosts
                      }
                    </div>
                  </div>
                  <ContactSection mapImage={mapImage} />
                </div>
              </div>
            </div>
        </div>
      </section>
    </div>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heroTitleImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mapStaticImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ceoLandscapeImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  employeeLandscapeImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    heading: PropTypes.string,
    blurbs: PropTypes.array,
    mainImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  ceoName: PropTypes.string,
  employeeName: PropTypes.string,
};

const IndexPage = ({ data }) => {
  setWasIndexLoaded(true);
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout isHero={true}>
      <IndexPageTemplate
        image={frontmatter.image}
        heroTitleImage={frontmatter.heroTitleImage}
        mapStaticImage={frontmatter.mapStaticImage}
        ceoLandscapeImage={frontmatter.ceoLandscapeImage}
        employeeLandscapeImage={frontmatter.employeeLandscapeImage}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        ceoName={frontmatter.ceoName}
        employeeName={frontmatter.employeeName}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heroTitleImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: NONE)
          }
        }        
        mapStaticImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }        
        ceoLandscapeImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }        
        employeeLandscapeImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        ceoName
        employeeName
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
            text
            description
          }
          heading
          mainImage {
              childImageSharp {
                gatsbyImageData(quality: 64, layout: CONSTRAINED, aspectRatio: 0.75)
              }
            }
        }
      }
    }
  }
`;
